<template>
    <div class="preview_area" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal()">
        <div class="report_section py-4">
            <div class="progress_container">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <!-- <div class="section_header mt-4"><h2>Workout Response</h2></div> -->
                <div class="tab_wpr coming-soon-wpr">
                    <h1>Coming Soon</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Workout Response',

        data () {
            return {

            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],


        watch: {
            modelValue (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        mounted () {
            const vm = this;

            if (vm.modelValue) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        },
    }
</script>

<style scoped>
    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width:25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }

    .share_playbook .tab_row li, .report_section .tab_row li {
        padding-bottom: 12px;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
    }
    .share_playbook .tab_row li:after, .report_section .tab_row li:after {
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #2F80ED;
        transition: all 0.3s ease-in-out;
    }

    .share_playbook .tab_row li.active, .report_section .tab_row li.active {
        color: #2f7eed;
    }
    .share_playbook .tab_row li.active:after, .report_section .tab_row li.active:after{
        left: 0;
    }

    .progress_container {
        max-width: 1170px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .coming-soon-wpr {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        color: #757575;
        font-style: italic;
    }
</style>
